<template>
  <div>
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <div class="essayTitle">{{ list.Title }}</div>
      <div class="rikind">{{ list.AKName }}</div>
      <div v-if="list.AImgUrl"
        class="essayImg">
        <img :src="list.AImgUrl"
          alt="" />
      </div>
      <div class="essayContent"
        v-html="list.Content"></div>
      <div class="video"
        v-if="list.Annex">
        <video style="width: 100%"
          :src="list.Annex"
          controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <van-button v-if="list.AllowSignup == 1 "
        class="button"
        @click="Activity"
        round
        type="info">活动申请</van-button>
    </div>
  </div>
</template>
<script>
var formatNum = function (num) {
  return num < 10 ? "0" + num : num
}
import { WeGetPBActivityDetail, WePBEnrolActivity } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Dialog } from 'vant';
import Pdfh5 from "pdfh5";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      list: {}, //活动详情
      aids: 0, //活动aid
      ActivityFrom: {}, //活动申请提交参数
      pdf: '',
      nowDate: '',
    };
  },
  created () {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
  },
  methods: {
    // 获取当前时间
    dataNow () {
      var nowDate = new Date();
      var year = nowDate.getFullYear()
      var month = formatNum(nowDate.getMonth() + 1)
      var day = formatNum(nowDate.getDate())
      var hour = formatNum(nowDate.getHours())
      var min = formatNum(nowDate.getMinutes())
      var sec = formatNum(nowDate.getSeconds())
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate(),
      }
      this.nowDate = year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec
      console.log(this.nowDate);
    },
    // 获取活动详情
    getRealInfo () {
      this.aids = this.$route.params.Id;
      WeGetPBActivityDetail({ aId: this.aids }).then((res) => {
        this.list = res.data.data;
        // this.pdf = 'https://api.qxzhqm.cn//upload/files/2022/3/ThingJS-X应用模板制作标准.pdf'
        // console.log(this.pdf);
        // this.list.Content = this.list.Content.replace(/<.*?>/ig,"")
        // console.log(this.list.Content);
      });
    },
    // 报名参加活动
    Activity () {
      this.ActivityFrom.AId = this.aids;
      this.ActivityFrom.pAKind = 1;
      this.ActivityFrom.OpenID = getOpenId();
      // this.ActivityFrom.OpenID = "oebDsw5XNiVzsIUaP__HJXNUd6krs"
      WePBEnrolActivity(this.ActivityFrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Dialog.alert({
            message: "提交成功!",
          }).then(() => {
            // on close
            this.$router.go(-1)
          });

        } else {
          Dialog.alert({
            message: "申请失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
  },
  mounted () {
    this.getRealInfo();
    this.dataNow()
  },
};
</script>
<style>
.button {
  width: 95%;
  margin: 15px 2.5%;
}
</style>